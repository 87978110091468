import { storeToRefs } from 'pinia';

export default function useProductLocalization() {
    const { currentProductCode } = storeToRefs(useProductStore());
    const productLocaleCode = ref();

    const i18n = useI18n();
    const {
        locale: globalLocale,
        locales: availableLocales,
        t: i18nT,
        loadLocaleMessages,
    } = i18n;

    const t = (key, ...args) => {
        return computed(() => {
            if (!productLocaleCode.value) {
                return i18nT(key, args);
            }

            // if no args are passed, set it to 1
            if (args.length < 1) {
                args = 1;
            }

            return i18nT(key, args, {
                locale: productLocaleCode.value,
            });
        });
    };

    const availableCodes = computed(() => {
        return availableLocales.value
            .filter((item) => item.code.includes('-'))
            .map((item) => {
                const productCode = item.code.split('-').pop();

                return productCode;
            });
    });

    const isAvailable = (code) => {
        return !!availableCodes.value.includes(code);
    };

    const properties = computed(() => {
        const [base, specific, product] = globalLocale.value.split('-');

        return {
            base,
            specific,
            product,
        };
    });

    const getCodeForUpdate = (product, specific = null, base = null) => {
        if (!base) {
            base = properties.value.base;
        }

        if (!specific) {
            specific = properties.value.specific;
        }

        let newCode = base;

        // add specific code if available
        if (specific) {
            newCode = `${newCode}-${specific}`;
        }

        // add product code if available
        if (product) {
            newCode = `${newCode}-${product}`;
        }

        return newCode;
    };

    const updateLocale = async (code) => {
        const { product } = properties.value;

        // if the code is available and not the current product, update the locale
        if (isAvailable(code) && product !== code) {
            const newCode = getCodeForUpdate(code);
            await loadLocaleMessages(newCode);

            productLocaleCode.value = newCode;
            return;
        }

        // if the code is not available, update the locale to the base
        productLocaleCode.value = null;
    };

    watch(currentProductCode, async (value) => {
        await updateLocale(value);
    });

    updateLocale(currentProductCode.value);

    return {
        t,
    };
}
